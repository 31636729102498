<template>
  <span>
    <font-awesome-icon :icon="['far','folder']" class="folder-label-icon"/>
    <span class="folder-label-text">
    {{ label }}
    </span>
  </span>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'FolderLabelCellRenderer',
  data() {
    return {
      label: null,
    }
  },
  created() {
    this.label = this.params.value? this.params.value: 'N/A';
  },
  methods: {
  }
});
</script>

<style lang="scss">
.folder-label-icon {
  font-size: 14px;
  position: absolute;
  top: 6px;
}
.folder-label-text {
  padding-left: 20px;
}

</style>