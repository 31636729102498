var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("font-awesome-icon", {
        staticClass: "folder-label-icon",
        attrs: { icon: ["far", "folder"] },
      }),
      _c("span", { staticClass: "folder-label-text" }, [
        _vm._v(" " + _vm._s(_vm.label) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }